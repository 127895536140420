import queryString from 'query-string';
import { deserialize } from 'utils/helpers';
import Sentry from 'utils/sentry';
import { isDomAvailable } from 'utils/ssr';

export const TRACKING_EVENTS = {
  attachmentUploadSuccess: 'Successful file upload',
  attachmentUploadError: 'File upload error',
  blockCreated: 'Block created',
  blockDeleted: 'Block deleted',
  blockOriginalViewed: 'Original Block Viewed',
  blockSearch: 'Search',
  blockLiked: 'Block liked',
  blockUnliked: 'Block unliked',
  blockMoved: 'Block moved',
  blockRated: 'Block rated',
  blockRatingRemoved: 'Block rating removed',
  blockCategoryAssigned: 'Category assigned',
  blockTimeLockAdded: 'Time lock added',
  blockTimeLockRemoved: 'Time lock removed',
  categoryCreated: 'Category created',
  commentCreated: 'Comment',
  commentDeleted: 'Deleted comment',
  commentResolved: 'Resolved comment',
  dashboardViewOpened: 'Open dashboard view',
  dashboardViewTypeChanged: 'Changed dashboard view',
  dashboardSorted: 'Sort dashboard',
  dashboardSearch: 'Search sessions',
  dialogViewed: 'Dialog Viewed',
  downloadAllAttachments: 'Download all attachments',
  displayTimeZoneChanged: 'Display time zone changed',
  folderCreated: 'Folder created',
  folderInfoChanged: 'Changed folder info',
  moveToFolder: 'Moved',
  notificationClicked: 'Notification clicked',
  notificationRead: 'Notification read',
  notificationUnread: 'Notification unread',
  notificationsOpened: 'Notifications opened',
  notificationsReadAll: 'Notifications read all',
  sessionCreated: 'Session created',
  sessionDayAdded: 'Session day created',
  sessionDayDuplicated: 'Session day duplicated',
  sessionDayExtracted: 'Session day extracted',
  sessionDuplicated: 'Session duplicated',
  sessionDeleted: 'Session deleted',
  sessionInfoChanged: 'Changed session info',
  sessionTimeZoneChange: 'Session time zone changed',
  sessionJoined: 'Sessions joined',
  sessionLeft: 'Left session',
  sessionOriginalViewed: 'Original Session Viewed',
  sessionPrintClicked: 'Print',
  sessionPrinted: 'Session Printed',
  sessionPdfExported: 'Session PDF Exported',
  sessionPptExport: 'Session PowerPoint Exported',
  sessionWordExport: 'Word Export',
  sessionUnlocked: 'Unlocked sessions',
  sessionOpened: 'Session opened in multi-plan',
  sessionClosed: 'Session closed in multi-plan',
  payment3DSVerification: 'Payment 3DS verification',
  payment3DSCanceled: 'Payment 3DS canceled',
  paymentError: 'Payment Error',
  plannerViewChanged: 'Session view changed',
  templateCreated: 'Template created',
  tutorialClosed: 'Tutorial Closed',
  tutorialStarted: 'Tutorial Started',
  tutorialStep: 'Tutorial Step',
  userMentioned: 'User mentioned',
  restoreBlock: 'Block restored',
  userLimitWarningShown: 'Limit warning shown',
  collaborationAdded: 'Collaborator added',
  collaborationDeleted: 'Collaborator removed',
  collaborationRoleChanged: 'Collaborator role changed',
  visitorLinkCreated: 'Visitor link created',
  visitorLinkDisabled: 'Visitor link disabled',
  trackExperimentStart: 'A/B Experiment started',
  error: 'Error',
  startedTimeTrackerMode: 'Started facilitation mode',
  endedTimeTrackerMode: 'Ended facilitation mode',
  dismissedTimeTrackerBanner: 'Dimissed time tracker banner',
  movedToNextBlock: 'Moved to next block',
  movedToNextBlockError: 'Moved to next block. No next block available',
  skippedBuffer: 'Skipped buffer',
  movedToSpecificBlock: 'Moved to specific block',
  changedTime: 'Changed time',
  imageUpload: 'Account image uploaded',
  imageDelete: 'Account image deleted',
  changedTimeTrackerMode: 'Changed Time Tracker Mode',
  dragOrigin: 'Drag Started With Element',
  openLibrarySidepanel: 'Open Library Sidepanel',
  contextMenuAction: 'User clicked action in context menu',
  useLibraryMethod: 'Use Library Method',
  blockEdited: 'Block edited',
  pickedIntent: 'Picked Intent',
  pickedRole: 'Picked current role',
  pickedWorkType: 'Picked work type',
  pickedUse: 'Picked type of use',
  pickedCompanySize: 'Picked company size',
  onboardingStepSkipped: 'Onboarding Step Skipped',
  onboardingStepCompleted: 'Onboarding Step Completed',
  multipleBlocksCreated: 'Multiple blocks created',
  workspaceNamed: 'Workspace Named',
  shareableAgendaLinkCreated: 'Shareable Agenda Link Created',
  shareableAgendaLinkDisabled: 'Shareable Agenda Link Disabled',
  linkCopied: 'Link Copied',
  invitationLinkCreated: 'Invitation Link Created',
  invitationLinkDisabled: 'Invitation Link Disabled',
  downloadQRCode: 'Download QR Code',
  taskAdded: 'Task Added',
  taskEdited: 'Task Edited',
  taskStatusChanged: 'Task Status Changed',
  toggleAdditionalInformation: 'Toggle Additional Information',
  copiedSessionToClipboard: 'Copied Session To Clipboard',
  aiActivity: 'AI activity',
  toggleMinimap: 'Toggle Minimap',
  requestCollaboration: 'Request Collaboration',
  sidebarClickToAddMembers: 'Sidebar Click To Add Members',
  removingGuest: 'Removing Guest',
  resizingPlannerColumn: 'Resizing Planner Column',
  reorderingPlannerColumn: 'Reordering Planner Column',
  toggleCollapseBlock: 'Toggle Block Collapsed',
  toggleColumnVisibility: 'Toggle Column Visibility',
  aiMessageSent: 'AI message sent',
  onboardingChecklistToggled: 'Onboarding Checklist Toggled',
  onboardingChecklistStepCompleted: 'Onboarding Checklist Step Completed',
  undo: 'Undo',
  redo: 'Redo',
  restoreSessionVersion: 'Restore Session Version',
  createSessionVersion: 'Create Session Version',
  editSessionVersion: 'Edit Session Version',
  deleteSessionVersion: 'Delete Session Version',
  previewSessionVersion: 'Preview Session Version',
  createSessionFromVersion: 'Create Session From Version',
  reorderedDays: 'Reordered Days',
  actionOnMultipleBlocks: 'Action on Multiple Blocks',
  columnLabelChange: 'Change Column Label',
  templatePickerDialogClosed: 'Template Picker Dialog Closed',
  noteBlockBackground: 'Note block background',
  exportStarted: 'Export started',
  exportCompleted: 'Export completed',
  exportFailed: 'Export failed',
  onboardingData: 'Onboarding data',
  welcomeVideoShowed: 'Welcome video showed',
  plannerSuggestedBlock: 'Planner suggested block',
};

export const TRACKING_VALUES = {
  collaboration: 'registered user',
  collaborationInvitation: 'invitation',
  blockAddedToLibraryFromSession: 'added to library from session',
  blockCopiedToSession: 'copied to another session',
  blockAddedToSessionFromLibrarySidepanel:
    'added to session from library sidepanel',
  blockCopiedWithDrag: 'from drag copy',
  blockCopiedWithDragFromSearch: 'from search results',
  blockDuplicated: 'duplicated',
  blockAddButton: 'add button',
  emptyTrackButton: 'empty-track-button',
  emptyGroupButton: 'group-empty-button',
  downloadAllCreatePhase: 'create',
  downloadAllDownloadPhase: 'download',
  daySession: 'day session',
  plannerMulti: 'multiday',
  plannerSingle: 'single',
  library: 'Library',
  guidedOnboardingV1: 'guided onboarding v1',
  aiBlock: 'AI',
  templateVisibilityPublic: 'public',
  templateVisibilityPrivate: 'private',
};

export const TRACKING_LOCATIONS = {
  root: 'Root',
  libraryModal: 'Library modal',
  libraryPage: 'Library page',
  plannerHeader: 'Planner Header',
  plannerSingleView: 'Planner Single View',
  plannerMultiView: 'Planner Multi View',
  blockModalView: 'Block Modal View',
  mobileBlockDetails: 'Mobile Block Details',
  sidepanelParkingLot: 'Sidepanel Parking Lot',
  sidepanelBlockInfo: 'Sidepanel Block Info',
  dashboard: 'Dashboard',
  dashboardSidebar: 'Dashboard-Sidebar',
  previewSessionDialog: 'Preview Session Dialog',
};

export const blockCreatedOrigin = (method, currentUser, teams) => {
  let methodOrigin = 'others';

  if (
    method.ownerId === currentUser.id ||
    method.owner?.id === currentUser.id
  ) {
    methodOrigin = 'user';
  } else if (teams.find(team => team.id === method.team?.id)) {
    methodOrigin = 'team_member';
  }

  return methodOrigin;
};

const readyListeners = [];

const shouldRun = () => {
  // checking if document exists prevents errors during testing when called in async functions
  // https://github.com/jsdom/jsdom/issues/2121
  const urlParams =
    isDomAvailable && window.document
      ? queryString.parse(window.location.search)
      : {};

  // We send tracking in production, or in dev/staging if additional GTM_PREVIEW_AUTH_PARAMS is set (it is on staging)
  // or if there is debug query parameter to allow anybody to test.
  return (
    process.env.RAILS_ENV === 'production' ||
    process.env.GTM_PREVIEW_AUTH_PARAMS ||
    urlParams.debug !== undefined
  );
};

export const registerSuperProperties = (
  superProperties,
  transformToUnderscore = true,
) => {
  let data = superProperties;
  if (transformToUnderscore) {
    data = deserialize(data);
  }

  if (shouldRun()) {
    // We use global dataLayer to send events to Google Tag Manager
    if (isDomAvailable) {
      if (!window.dataLayer) {
        window.dataLayer = [];
      }
      window.dataLayer.push({
        event: 'Mixpanel Super Property',
        mixpanelSuperProperties: data,
      });
    }
  } else if (process.env.LOG_ANALYTICS_TRACKING === '1') {
    //eslint-disable-next-line
    console.info(
      'Mixpanel Super Property Registered: %c%s',
      'font-weight:bold',
      data,
    );
    if (isDomAvailable) {
      const currentSuperProperties = sessionStorage.getItem(
        'MIXPANEL_SUPER_PROPERTIES',
      );
      sessionStorage.setItem(
        'MIXPANEL_SUPER_PROPERTIES',
        JSON.stringify({
          ...(currentSuperProperties ? JSON.parse(currentSuperProperties) : {}),
          ...data,
        }),
      );
    }
  }
};

const track = (name, data, transformToUnderscore = true) => {
  if (transformToUnderscore) {
    data = deserialize(data);
  }

  if (shouldRun()) {
    // We use global dataLayer to send events to Google Tag Manager
    if (isDomAvailable) {
      if (!window.dataLayer) {
        window.dataLayer = [];
      }
      window.dataLayer.push({
        appEventData: undefined,
        appEventName: undefined,
      });
      window.dataLayer.push({
        event: 'App Event',
        appEventData: data,
        appEventName: name,
      });
    }
  } else if (process.env.LOG_ANALYTICS_TRACKING === '1') {
    const currentSuperProperties = sessionStorage.getItem(
      'MIXPANEL_SUPER_PROPERTIES',
    );
    //eslint-disable-next-line
    console.info(
      'Sent to GTM in production: %c%s',
      'font-weight:bold',
      name,
      currentSuperProperties
        ? { ...data, ...JSON.parse(currentSuperProperties) }
        : data,
    );
  }

  if (name !== TRACKING_EVENTS.error) {
    Sentry.addBreadcrumb({
      message: name,
      category: 'action',
      data: data,
    });
  }
};

const ready = fn => {
  readyListeners.push(fn);
};

// We need to setup the object and loaded method on the window
// so we can use it from Google Tag Manager
const analytics = (isDomAvailable && window.analytics) || {};

if (!analytics.loaded) {
  analytics.loaded = () => {
    readyListeners.forEach(function (fn) {
      fn();
    });
  };
}

export const setupFormSubmtAnalytics = () => {
  document.querySelectorAll('form[data-track-submit-event]').forEach(form => {
    form.addEventListener('submit', event => {
      // Selecting the source of dataset so we can track separate events for separate buttons.
      // If the clicked button has a data-track-submit-event attribute then use the
      // data stored on the button for tracking.
      const dataset = event.submitter.dataset.trackSubmitEvent
        ? event.submitter.dataset
        : event.target.dataset;

      track(
        dataset.trackSubmitEvent,
        JSON.parse(dataset.trackSubmitData || null),
      );
    });
  });
};

export const trackTaskStatusChange = checked => {
  track(TRACKING_EVENTS.taskStatusChanged, {
    checked,
  });
};

if (isDomAvailable) {
  if (!window.sessionlab) {
    window.sessionlab = {};
  }
  window.sessionlab = { ...window.sessionlab, track };
}

export default {
  track: track,
  ready: ready,
};
